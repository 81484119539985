import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Result
      status={404}
      title={t('NotFound.title')}
      extra={
        <a href={import.meta.env.VITE_HOME_PAGE}>
          <Button type="primary">{t('NotFound.button')}</Button>
        </a>
      }
    />
  )
}
