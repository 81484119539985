import { useLazyQuery } from '@apollo/client'
import { Alert } from 'antd'
import { ReactComponent as Icon } from '../images/add-to-pass-wallet.svg'
import { GET_PASS_SHARE_DOWNLOAD } from '../pages/Queries'
import Loader from './Loader'

interface PassWalletButtonProps {
  id: string
}

export const PassWalletButton = ({ id }: PassWalletButtonProps) => {
  const [loadPassShareUrl, { called, loading, data, error }] = useLazyQuery(
    GET_PASS_SHARE_DOWNLOAD,
    {
      variables: { id },
    }
  )

  function handleClick(): void {
    loadPassShareUrl().then((result) => {
      if (result.data?.passShare?.pass.download.url !== undefined)
        window.location.href = `https://passwallet.page.link/?apn=com.attidomobile.passwallet&link=${encodeURI(
          result.data?.passShare.pass.download.url
        )}`
    })
  }

  if (called && loading) return <Loader />

  if (error) return <Alert message={error.message} type="error" />

  if (!called) {
    return (
      <button onClick={handleClick} className={'w-full max-w-[300px]'}>
        <Icon />
      </button>
    )
  }

  return (
    <a
      href={`https://passwallet.page.link/?apn=com.attidomobile.passwallet&link=${encodeURI(
        data?.passShare?.pass.download.url || ''
      )}`}
      className={'w-full max-w-[300px]'}
    >
      <Icon />
    </a>
  )
}
