import { useSearchParams } from 'react-router-dom'
import { LanguageSelect } from './components/LanguageSelect'
import { NotFound } from './pages/NotFound'
import { PassShareDetail } from './pages/PassShareDetail'

function App() {
  const [params] = useSearchParams()
  const token = params.get('token')
  if (!token) {
    return <NotFound />
  }
  return (
    <>
      <div className="flex justify-end p-4 bg-gray-100">
        <LanguageSelect />
      </div>
      <PassShareDetail id={token} />
    </>
  )
}

export default App
