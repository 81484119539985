import { PKPassPreview, PassData } from '@tiketo/pkpass-builder-ui'
import { useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import QrCode from './QrCode'
import PassWalletInformation from './PassWalletInformation'

// preview https://pass.passmachine.cz/?pass=6399a4ef8128d3c82a0bee9678f922aa

interface LandingPageProps {
  templateData: PassData
  isGooglePayEnabled: boolean
}

export enum WalletDetail {
  APPLE_WALLET = 'applewallet',
  GOOGLE_WALLET = 'googlewallet',
  PASS_WALLET = 'passwallet',
}

const LandingPage = ({
  templateData,
  isGooglePayEnabled,
}: LandingPageProps) => {
  const [walletDetailOption, setWalletDetailOption] = useState<
    WalletDetail | undefined
  >()

  return (
    <div className="min-h-screen md:flex flex-col">
      <div className="flex-grow flex items-center justify-center md:flex-row flex-col">
        <div className="max-w-screen-lg p-8 w-full flex justify-center items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full ">
            <div className="flex flex-col p-4 items-center">
              {templateData && (
                <div className={'sm:scale-95 scale-75'}>
                  <PKPassPreview
                    values={templateData}
                    removeVariablePlaceholders={true}
                  />
                </div>
              )}
            </div>

            {!walletDetailOption && (
              <PassWalletInformation
                setIsWalletDetailOpen={setWalletDetailOption}
                isGooglePayEnabled={isGooglePayEnabled}
              />
            )}

            {walletDetailOption && (
              <div className={'flex flex-col items-start mt-6 gap-6'}>
                <div
                  className={
                    'flex items-center justify-start bg-gray-100 hover:bg-gray-200 px-6 py-2 rounded-2xl cursor-pointer w-min'
                  }
                  onClick={() => setWalletDetailOption(undefined)}
                >
                  <ArrowLeftOutlined className={'text-4xl'} />
                </div>
                <QrCode
                  app={
                    walletDetailOption === WalletDetail.PASS_WALLET
                      ? WalletDetail.PASS_WALLET
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
