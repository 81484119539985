import { Select } from 'antd'
import i18n from '../i18n'
import { useLocalForage } from '@zikwall/use-localforage'

export const LanguageSelect = () => {
  const [selectedLanguage, setSelectedLanguage] = useLocalForage(
    'currentLanguage',
    'en'
  )

  if (i18n.language !== selectedLanguage) {
    i18n.changeLanguage(selectedLanguage!)
  }
  const handleLanguageChange = (value: string) => {
    setSelectedLanguage(value)
    i18n.changeLanguage(value)
  }
  return (
    <Select
      defaultValue="en"
      style={{ width: 80 }}
      onChange={handleLanguageChange}
      value={selectedLanguage}
      options={[
        { value: 'en', label: 'EN' },
        { value: 'cz', label: 'CZ' },
        { value: 'sk', label: 'SK' },
        { value: 'de', label: 'DE' },
      ]}
    />
  )
}
