import { graphql } from '../gql'

export const GET_PASS_SHARE = graphql(`
  query getPassShare($id: ID!) {
    passShare(id: $id) {
      expiration
      pass {
        parameters
        voided
        expirationDate
        files {
          background
          footer
          icon
          logo
          strip
          thumbnail
        }
        template {
          downloadUrl
          googlePayEnabled
        }
        project {
          id
          name
          styles
        }
      }
    }
  }
`)

export const GET_PASS_SHARE_DOWNLOAD = graphql(`
  query getPassShareDownload($id: ID!) {
    passShare(id: $id) {
      pass {
        download {
          url
          expiration
        }
      }
    }
  }
`)

export const GET_PASS_SHARE_DOWNLOAD_GOOGLE_PAY = graphql(`
  query getPassShareDownloadGooglePay($id: ID!) {
    passShare(id: $id) {
      pass {
        downloadGooglePay {
          url
          expiration
        }
      }
    }
  }
`)
