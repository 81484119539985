import { useLazyQuery } from '@apollo/client'
import { Alert } from 'antd'
import { ReactComponent as Icon } from '../images/add-to-google-wallet.svg'
import { GET_PASS_SHARE_DOWNLOAD_GOOGLE_PAY } from '../pages/Queries'
import Loader from './Loader'
import { useEffect } from 'react'
export const GooglePayPassButton = ({ id }: { id: string }) => {
  const [loadPassShareUrl, { called, loading, data, error }] = useLazyQuery(
    GET_PASS_SHARE_DOWNLOAD_GOOGLE_PAY,
    {
      variables: { id },
    }
  )

  useEffect(() => {
    handleClick()
  }, [])

  function handleClick(): void {
    loadPassShareUrl().then((result) => {
      if (result.data?.passShare?.pass.downloadGooglePay?.url !== undefined) {
        window.location.href = result.data?.passShare.pass.downloadGooglePay.url
      }
    })
  }

  if (called && loading) return <Loader />

  if (error) return <Alert message={error.message} type="error" />

  if (!called) {
    return (
      <button
        onClick={handleClick}
        className={'w-full max-w-[300px] justify-center flex'}
      >
        <Icon />
      </button>
    )
  }

  return (
    <a
      href={`https://passwallet.page.link/?apn=com.attidomobile.passwallet&link=${encodeURI(
        data?.passShare?.pass.downloadGooglePay?.url || ''
      )}`}
      className={'w-full max-w-[300px] justify-center flex'}
    >
      <Icon />
    </a>
  )
}
