import { ReactComponent as Icon } from '../images/add-to-apple-wallet.svg'
import { useLazyQuery } from '@apollo/client'
import { GET_PASS_SHARE_DOWNLOAD } from '../pages/Queries'
import { useEffect } from 'react'
import Loader from './Loader'
import { Alert } from 'antd'

interface AppleWalletButtonProps {
  id: string
}
export const AppleWalletButton = ({ id }: AppleWalletButtonProps) => {
  const [loadPassShareUrl, { called, loading, data, error }] = useLazyQuery(
    GET_PASS_SHARE_DOWNLOAD,
    {
      variables: { id },
    }
  )

  // Trigger onClick so on iOS the iOS template is shown right after onLoad
  useEffect(() => {
    handleClick()
  }, [])

  function handleClick(): void {
    loadPassShareUrl().then((result) => {
      if (result.data?.passShare?.pass.download.url !== undefined)
        window.location.href = result.data?.passShare.pass.download.url
    })
  }

  if (called && loading) return <Loader />

  if (error) return <Alert message={error.message} type="error" />

  if (!called) {
    return (
      <button onClick={handleClick} className={'w-full max-w-[300px]'}>
        <Icon />
      </button>
    )
  }

  return (
    <a
      href={data?.passShare?.pass.download.url || ''}
      className={'w-full max-w-[300px]'}
    >
      <Icon />
    </a>
  )
}
