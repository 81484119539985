import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'

const Loader = ({
  title,
  fullScreen,
}: {
  title?: string
  fullScreen?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={`flex flex-col justify-center items-center w-full gap-6 px-4 ${
        fullScreen && 'h-screen'
      }`}
    >
      <Spin size="large" className={'w-full'} />
      {title && (
        <h1 className={'text-center text-3xl font-bold'}>{t(title)}</h1>
      )}

      <p className={'text-gray-500 text-lg'}>{t('justAMoment')}</p>
    </div>
  )
}

export default Loader
