import QRCode from 'qrcode.react'
import {
  CameraOutlined,
  QrcodeOutlined,
  WalletOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const QrCode = ({ app }: { app?: string }) => {
  const { t } = useTranslation()
  let currentUrl = window.location.href

  if (app) currentUrl += `&app=${app}`

  return (
    <>
      <h1 className={'text-3xl font-bold mb-10'}>{t('addPassToWallet')}</h1>

      <div className={'flex gap-4 items-center'}>
        <QRCode value={currentUrl} size={128} />

        <ul className="list-none">
          <li className="flex items-center mb-2">
            <CameraOutlined className="mr-4 text-2xl text-gray-800" />
            {t('startCamera')}
          </li>
          <li className="flex items-center mb-2">
            <QrcodeOutlined className="mr-4 text-2xl text-gray-800" />
            {t('scanQRCodeAndTap')}
          </li>
          <li className="flex items-center mb-2">
            <WalletOutlined className="mr-4 text-2xl text-gray-800" />
            {t('addPassToWalletApp')}
          </li>
        </ul>
      </div>
    </>
  )
}

export default QrCode
