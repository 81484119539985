import { useQuery } from '@apollo/client'
import { Alert } from 'antd'
import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import { PassData } from '@tiketo/pkpass-builder-ui'
import LandingPage from '../components/LandingPage'
import LandingPageApple from '../components/LandingPageApple'
import LandingPageGoogle from '../components/LandingPageGoogle'
import Loader from '../components/Loader'
import { NotFound } from './NotFound'
import { GET_PASS_SHARE } from './Queries'

interface PassShareDetailProps {
  id: string
}

export const PassShareDetail = ({ id }: PassShareDetailProps) => {
  const [templateData, setTemplateData] = useState<PassData | undefined>(
    undefined
  )
  const { data, loading, error } = useQuery(GET_PASS_SHARE, {
    variables: { id },
  })

  const templateDownloadUrl = data?.passShare?.pass.template?.downloadUrl
  const isGooglePayEnabled =
    data?.passShare?.pass.template?.googlePayEnabled ?? false

  useEffect(() => {
    if (!templateDownloadUrl) {
      return
    }
    const fetchTemplate = async () => {
      const res = await fetch(templateDownloadUrl)
      return await res.text()
    }

    fetchTemplate().then((_data: string) => {
      const passParameters = JSON.parse(data.passShare?.pass.parameters || '{}')
      let translatedTemplateData = _data
      for (const key in passParameters) {
        const str = JSON.stringify(`${passParameters[key]}`)
        translatedTemplateData = translatedTemplateData.replaceAll(
          '${' + key + '}',
          str.substring(1, str.length - 1)
        )
      }
      const templateData = JSON.parse(translatedTemplateData)
      const files = data.passShare?.pass?.files
      if (files) {
        for (const [name, val] of Object.entries(files)) {
          if (val) {
            templateData[name + '.png'] = val
          }
        }
      }
      if (data.passShare?.pass?.voided !== undefined)
        templateData['pass.json'].voided = data.passShare?.pass?.voided
      if (data.passShare?.pass?.expirationDate !== undefined)
        templateData['pass.json'].expirationDate =
          data.passShare?.pass?.expirationDate
      setTemplateData(templateData)
    })
  }, [templateDownloadUrl, data])

  if (!id) {
    return <NotFound />
  }

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  if (loading) {
    return <Loader title={'yourCardIsBeingGenerated'} fullScreen={true} />
  }

  const passShare = data?.passShare

  if (!passShare) {
    return <NotFound />
  }

  if (!isIOS && !isAndroid && templateData) {
    return (
      <LandingPage
        templateData={templateData}
        isGooglePayEnabled={isGooglePayEnabled}
      />
    )
  }

  if (passShare.pass.project?.styles) {
    const style = document.getElementById('dynamic-styles')
    if (style) {
      style.innerHTML = passShare.pass.project?.styles
    }
  }

  return (
    <div className={'flex flex-col items-center mt-6 mx-4'}>
      <div className={'sm:px-10 px-6 pt-5 max-w-[450px]'}>
        {isIOS && <LandingPageApple id={id} />}
        {isAndroid && (
          <LandingPageGoogle id={id} isGooglePayEnabled={isGooglePayEnabled} />
        )}
      </div>
    </div>
  )
}
