import AddToWalletButton from './AddToWalletButton'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Divider } from 'antd'
import { WalletDetail } from './LandingPage'

interface PassWalletInformationProps {
  setIsWalletDetailOpen: React.Dispatch<
    React.SetStateAction<WalletDetail | undefined>
  >
  isGooglePayEnabled: boolean
}

const PassWalletInformation = ({
  setIsWalletDetailOpen,
  isGooglePayEnabled,
}: PassWalletInformationProps) => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-col mt-6'}>
      <img
        src="logo.png"
        alt="Placeholder"
        className="mb-2 w-32 pass-share-logo"
      />
      <h1 className="text-3xl font-bold mt-6 mb-2">{t('addPassToWallet')}</h1>
      <h2 className="text-xl mb-8">{t('chooseOption')}</h2>

      <div className={'flex flex-col'}>
        <div onClick={() => setIsWalletDetailOpen(WalletDetail.APPLE_WALLET)}>
          <AddToWalletButton iPhone={true} googlePay={false} />
        </div>

        {isGooglePayEnabled && (
          <>
            <Divider />
            <div
              onClick={() => setIsWalletDetailOpen(WalletDetail.GOOGLE_WALLET)}
            >
              <AddToWalletButton iPhone={false} googlePay={true} />
            </div>
          </>
        )}

        <div
          onClick={() => setIsWalletDetailOpen(WalletDetail.PASS_WALLET)}
          className={'mt-6'}
        >
          <AddToWalletButton iPhone={false} googlePay={false} />
        </div>
      </div>
    </div>
  )
}

export default PassWalletInformation
