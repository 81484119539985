import {
  AndroidOutlined,
  AppleOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

enum WalletKeys {
  AppleWallet = 'apple_wallet',
  PassWallet = 'pass_wallet',
  GooglePay = 'google_pay',
}

const strongTitles: { [key in WalletKeys]: string } = {
  [WalletKeys.AppleWallet]: 'Apple',
  [WalletKeys.PassWallet]: 'Google',
  [WalletKeys.GooglePay]: 'Google',
}

const translationKeys: { [key in WalletKeys]: string } = {
  [WalletKeys.AppleWallet]: 'wallet',
  [WalletKeys.PassWallet]: 'pass_wallet',
  [WalletKeys.GooglePay]: 'wallet',
}

interface AddToWalletButtonProps {
  iPhone: boolean
  googlePay: boolean
}

const AddToWalletButton = ({ iPhone, googlePay }: AddToWalletButtonProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={
        'flex border rounded-3xl p-6 justify-between items-center hover:bg-gray-100 cursor-pointer'
      }
    >
      <div className={'flex items-center'}>
        <strong className={'border-r-2 pr-5 text-xl'}>
          <div className={'flex items-center gap-2'}>
            {iPhone ? (
              <>
                <AppleOutlined />
                iPhone
              </>
            ) : (
              <>
                <AndroidOutlined />
                android
              </>
            )}
          </div>
        </strong>
        <p className={'px-5 text-lg'}>
          <strong>
            {iPhone
              ? strongTitles[WalletKeys.AppleWallet]
              : strongTitles[
                  googlePay ? WalletKeys.GooglePay : WalletKeys.PassWallet
                ]}{' '}
          </strong>
          {t(
            iPhone
              ? translationKeys[WalletKeys.AppleWallet]
              : translationKeys[
                  googlePay ? WalletKeys.GooglePay : WalletKeys.PassWallet
                ]
          )}
        </p>
      </div>
      <ArrowRightOutlined />
    </div>
  )
}

export default AddToWalletButton
