/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query getPassShare($id: ID!) {\n    passShare(id: $id) {\n      expiration\n      pass {\n        parameters\n        voided\n        expirationDate\n        files {\n          background\n          footer\n          icon\n          logo\n          strip\n          thumbnail\n        }\n        template {\n          downloadUrl\n          googlePayEnabled\n        }\n        project {\n          id\n          name\n          styles\n        }\n      }\n    }\n  }\n": types.GetPassShareDocument,
    "\n  query getPassShareDownload($id: ID!) {\n    passShare(id: $id) {\n      pass {\n        download {\n          url\n          expiration\n        }\n      }\n    }\n  }\n": types.GetPassShareDownloadDocument,
    "\n  query getPassShareDownloadGooglePay($id: ID!) {\n    passShare(id: $id) {\n      pass {\n        downloadGooglePay {\n          url\n          expiration\n        }\n      }\n    }\n  }\n": types.GetPassShareDownloadGooglePayDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPassShare($id: ID!) {\n    passShare(id: $id) {\n      expiration\n      pass {\n        parameters\n        voided\n        expirationDate\n        files {\n          background\n          footer\n          icon\n          logo\n          strip\n          thumbnail\n        }\n        template {\n          downloadUrl\n          googlePayEnabled\n        }\n        project {\n          id\n          name\n          styles\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPassShare($id: ID!) {\n    passShare(id: $id) {\n      expiration\n      pass {\n        parameters\n        voided\n        expirationDate\n        files {\n          background\n          footer\n          icon\n          logo\n          strip\n          thumbnail\n        }\n        template {\n          downloadUrl\n          googlePayEnabled\n        }\n        project {\n          id\n          name\n          styles\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPassShareDownload($id: ID!) {\n    passShare(id: $id) {\n      pass {\n        download {\n          url\n          expiration\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPassShareDownload($id: ID!) {\n    passShare(id: $id) {\n      pass {\n        download {\n          url\n          expiration\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPassShareDownloadGooglePay($id: ID!) {\n    passShare(id: $id) {\n      pass {\n        downloadGooglePay {\n          url\n          expiration\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPassShareDownloadGooglePay($id: ID!) {\n    passShare(id: $id) {\n      pass {\n        downloadGooglePay {\n          url\n          expiration\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;