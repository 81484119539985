import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Icon } from '../images/apple-wallet-icon.svg'
import { AppleWalletButton } from './AppleWalletButton'

const LandingPageApple = ({ id }: { id: string }) => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-col gap-12 text-center items-center pb-10'}>
      <div className={'flex flex-col gap-6 text-center items-center'}>
        <h1 className={'text-4xl font-bold'}>{t('openTheWalletApp')}</h1>
        <div className={'bg-black p-4 rounded-2xl'}>
          <Icon className={'max-w-full w-[60px] h-auto'} />
        </div>
      </div>

      <div>
        <p className={'text-2xl font-bold mb-2'}>
          {t('addingCardUnsuccessful')}
        </p>
        <p className={'text-xl text-gray-600'}>
          {t('selectButtonBelowAndSave')}
        </p>
      </div>
      <a href="shoebox://">
        <Button
          size="large"
          type="primary"
          style={{ width: 300, height: 80, fontSize: 22, borderRadius: 20 }}
        >
          Open Wallet
        </Button>
      </a>
      <AppleWalletButton id={id} />
    </div>
  )
}

export default LandingPageApple
