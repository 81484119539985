import { StyleProvider } from '@ant-design/cssinjs'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ApolloWrapper } from './apollo-client'
import './i18n'
import './index.css'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://f135f885ad658cab6e6d002616b58738@o4505340787687424.ingest.sentry.io/4505622861840384',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://api.get.tiketo.eu/graphql'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // https://github.com/ant-design/ant-design/issues/38794
  <StyleProvider hashPriority="high">
    <BrowserRouter>
      <React.StrictMode>
        <ApolloWrapper>
          <App />
        </ApolloWrapper>
      </React.StrictMode>
    </BrowserRouter>
  </StyleProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
