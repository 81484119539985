import { PassWalletButton } from './PassWalletButton'
import { useTranslation } from 'react-i18next'
import { GooglePayPassButton } from './GooglePayPassButton'
import { useSearchParams } from 'react-router-dom'
import { WalletDetail } from './LandingPage'

const LandingPageGoogle = ({
  id,
  isGooglePayEnabled,
}: {
  id: string
  isGooglePayEnabled: boolean
}) => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const app = params.get('app')

  return (
    <div className={'flex flex-col gap-12 text-center'}>
      <div className={'flex flex-col gap-6 text-center items-center'}>
        <h1 className={'text-4xl font-bold'}>{t('addPassToWalletApp')}</h1>
        {isGooglePayEnabled && app !== WalletDetail.PASS_WALLET ? (
          <GooglePayPassButton id={id} />
        ) : (
          <PassWalletButton id={id} />
        )}
      </div>
    </div>
  )
}

export default LandingPageGoogle
