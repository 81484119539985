import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './locales.json'
import detectBrowserLanguage from 'detect-browser-language'

const defaultLanguage = detectBrowserLanguage()

i18n.use(initReactI18next).init({
  resources: resources,
  lng: defaultLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
